import React, { useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Company from './Company';

const VOTE = gql`
  mutation Vote($winning_company_id: uuid!, $losing_company_id: uuid!) {
    insert_votes(objects: {losing_company_id: $losing_company_id, winning_company_id: $winning_company_id}) {
      returning {
        id
      }
    }
  }
`;

const MATCHUP_RECORD = gql`
  query MatchupRecord($id: String!) {
    matchup_record(where: {id: {_eq: $id}}) {
      id
      first_count
      second_count
      first_company {
        id
      }
      second_company {
        id
      }
    }
  }
`;

function Matchup({companies}) {
  // const { loading, error, data } = useQuery(COMPANIES);
  const [vote, { data: voteData, loading, error }] = useMutation(VOTE);
  const [matchupRecord, setMatchupRecord] = useState(null);
  const [current, setCurrent] = useState([0, 1]);

  const [getMatchup] = useLazyQuery(
    MATCHUP_RECORD, {
      onCompleted: (data) => {
        const matchup_record = data.matchup_record[0]
        console.log("matchup_record", {
          [matchup_record.first_company.id]: matchup_record.first_count,
          [matchup_record.second_company.id]: matchup_record.second_count,
        });
        setMatchupRecord({
          [matchup_record.first_company.id]: matchup_record.first_count,
          [matchup_record.second_company.id]: matchup_record.second_count,
        })
      },
    }
  );
  if (error) return <p>Error :(</p>;

  const castVote = async (winnerIndex, losingIndex) => {
    await vote({ variables: {
      winning_company_id: companies[winnerIndex].id,
      losing_company_id: companies[losingIndex].id,
    }});

    getMatchup({ variables: {
      id: [companies[winnerIndex].id, companies[losingIndex].id].sort().join(''),
    }})

    const losingCurrent = losingIndex === current[0] ? 0 : 1;
    let nextIndex = current[losingCurrent];
    do {
      nextIndex = (nextIndex + 1) % companies.length;
    } while (nextIndex === current[1 - losingCurrent]);
    const nextCurrent = {...current};
    nextCurrent[losingCurrent] = nextIndex;
    setTimeout(() => {
      setMatchupRecord(null);
      setCurrent(nextCurrent);
    }, 2000);
  }

  return (
    <div className="columns">
      <Company 
        key={current[0]}
        flipping
        onSelect={() => castVote(current[0], current[1])}
        count={matchupRecord && matchupRecord[companies[current[0]].id] / (matchupRecord[companies[current[0]].id] + matchupRecord[companies[current[1]].id])}
        {...companies[current[0]]}
      />
      <Company 
        key={current[1]}
        flipping
        onSelect={() => castVote(current[1], current[0])}
        count={matchupRecord && matchupRecord[companies[current[1]].id] / (matchupRecord[companies[current[0]].id] + matchupRecord[companies[current[1]].id])}
        {...companies[current[1]]}
      />
    </div>
  )
}

export default Matchup;
