import React from 'react';
import { gql } from 'apollo-boost';
import { useSubscription } from '@apollo/react-hooks';

const SCORES_SUBSCRIPTION = gql`
  subscription rankedScores {
    scores_aggregate(order_by: {rating: desc_nulls_last}) {
      nodes {
        id
        rating
        wins
        loses
        company {
          name
        }
      }
    }
  }
`;

function Rank({id, name, description, logoUrl, onSelect}) {
  const { data, loading, error } = useSubscription(SCORES_SUBSCRIPTION);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const {scores_aggregate: { nodes } } = data;

  return (
    <ol>
      {nodes.map(({id, wins, loses, company}) =>
        <li key={id}>{company.name} - {wins}-{loses}</li>
      )}
    </ol>
  );
}

export default Rank;
