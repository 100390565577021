import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Matchup from './Matchup';

const COMPANIES = gql`
{
  companies {
    id
    name
    description
    logoUrl
  }
}
`;

function MatchupContainer() {
  const { loading, error, data } = useQuery(COMPANIES);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const companies = data.companies.sort((a, b) => 0.5 - Math.random());

  return (
    <Matchup companies={companies}/>
  );
}

export default MatchupContainer;
