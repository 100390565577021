import React from 'react';
import classNames from 'classnames';

function Company({id, name, description, logoUrl, onSelect, flipping, count}) {
  return (
    <div className={classNames('matchup', 'is-half', 'column', {flipping})} onClick={() => !count && onSelect(id)}>
      <img height='100%' src={logoUrl} alt={name} />
      {/* <p>{description}</p> */}
      { count &&
        <div className="bubble-container">
          <div className="bubble">{Math.round(count * 100)}%</div>
        </div>
      }
    </div>
  );
}

export default Company;
