import React from 'react';
import 'react-bulma-components/dist/react-bulma-components.min.css';
import { Section, Container } from 'react-bulma-components';

import './App.scss';
import Header from './components/Header';
import MatchupContainer from './components/MatchupContainer';
import RankContainer from './components/RankContainer';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom'

function App() {
  return (
    <Router>
      <Header />
      <Section>
        <Container>
          <Switch>
            <Route exact path="/">
              <MatchupContainer />
            </Route>
            <Route path="/rank">
              <RankContainer />
            </Route>
          </Switch>
        </Container>
      </Section>
    </Router>
  );
}

export default App;
