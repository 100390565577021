import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar } from 'react-bulma-components';

function Header() {
  return (
    <Navbar>
      <Navbar.Brand>
        <NavLink exact className="navbar-item" activeClassName="is_active" to="/">
          CompanyMash
        </NavLink>
      </Navbar.Brand>
      <NavLink className="navbar-item" activeClassName="is_active" to="/rank">
        Rankings
      </NavLink>
    </Navbar>
  );
}
export default Header;
